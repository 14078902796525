exports.onClientEntry = () => {
  var $ = require('jquery')
  window.jQuery = $

  /*CSS*/
  require('./src/assets/css/settings.css')
  require('./src/assets/css/core.css')
  require('./src/assets/css/style-custom.css')
  require('./src/assets/css/style.css')
  require('./src/assets/custom-js/goodlayers-core/include/css/page-builder.css')

  /*JS*/
  require('./src/assets/custom-js/jquery/jquery.js')
  require('./src/assets/custom-js/jquery/jquery-migrate.min.js')
  require('./src/assets/custom-js/html5.js')
  require('./src/assets/custom-js/jquery/ui/effect.min.js')
  require('./src/assets/custom-js/script-core.js')
  require('./src/assets/custom-js/goodlayers-core/plugins/combine/script.js')
  require('./src/assets/custom-js/goodlayers-core/include/js/page-builder.js')
  require('./src/assets/custom-js/plugins.js')
  require('./src/assets/custom-js/scripts.js')

  console.log('qasdfgbnmfdsa')
}
