// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-business-development-case-study-js": () => import("/opt/build/repo/src/pages/business-development-case-study.js" /* webpackChunkName: "component---src-pages-business-development-case-study-js" */),
  "component---src-pages-business-strategy-js": () => import("/opt/build/repo/src/pages/business-strategy.js" /* webpackChunkName: "component---src-pages-business-strategy-js" */),
  "component---src-pages-case-studies-js": () => import("/opt/build/repo/src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-study-js": () => import("/opt/build/repo/src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-creative-services-js": () => import("/opt/build/repo/src/pages/creative-services.js" /* webpackChunkName: "component---src-pages-creative-services-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-market-research-case-study-js": () => import("/opt/build/repo/src/pages/market-research-case-study.js" /* webpackChunkName: "component---src-pages-market-research-case-study-js" */),
  "component---src-pages-market-research-js": () => import("/opt/build/repo/src/pages/market-research.js" /* webpackChunkName: "component---src-pages-market-research-js" */),
  "component---src-pages-post-investment-support-js": () => import("/opt/build/repo/src/pages/post-investment-support.js" /* webpackChunkName: "component---src-pages-post-investment-support-js" */),
  "component---src-pages-product-management-case-study-js": () => import("/opt/build/repo/src/pages/product-management-case-study.js" /* webpackChunkName: "component---src-pages-product-management-case-study-js" */),
  "component---src-pages-services-js": () => import("/opt/build/repo/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-technology-advisory-js": () => import("/opt/build/repo/src/pages/technology-advisory.js" /* webpackChunkName: "component---src-pages-technology-advisory-js" */),
  "component---src-pages-training-advisory-case-study-js": () => import("/opt/build/repo/src/pages/training-advisory-case-study.js" /* webpackChunkName: "component---src-pages-training-advisory-case-study-js" */)
}

